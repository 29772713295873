import React from "react";
import webtim from "../assets/webtim.jpg";

function Webtim() {
  return (
    <div style={{ textAlign: "center" }}>
      <img style={{ width: "100%" }} alt="webtim" src={webtim} />
    </div>
  );
}

export default Webtim;
