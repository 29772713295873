import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Main from "./views/Main";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Galerija from "./views/Galerija";
import NotFound from "./views/NotFound";
import Kontakt from "./views/Kontakt";
import Sponzori from "./views/Sponzori";
import Obavijesti from "./views/Obavijesti";
import Admin from "./views/Admin";
import Dashboard from "./views/Dashboard";
import Signout from "./views/Signout";
import Compete from "./views/Compete";
import Webtim from "./views/Webtim";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import { useEffect } from "react";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default function App() {
  const location = useLocation();
  const [user] = useAuthState(auth);

  return (
    <>
      <Navbar />
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="page" timeout={500}>
          <Routes location={location}>
            <Route path="/" element={<Main />} />
            <Route path="/obavijesti" element={<Obavijesti />} />
            <Route path="/galerija" element={<Galerija />} />
            <Route path="/sponzori" element={<Sponzori />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/natjecanje" element={<Compete />} />
            <Route path="/admin" element={<Admin />} />
            <Route
              path="/dashboard"
              element={user ? <Dashboard /> : <NotFound />}
            />
            <Route
              path="/signout"
              element={user ? <Signout /> : <NotFound />}
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/webtim" element={<Webtim />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <ScrollToTop />
      <Footer />
    </>
  );
}
