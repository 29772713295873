import React from "react";
import "../styles/footer.css";
import sms from "../assets/sms_logo.svg";
import fightbotics from "../assets/logo.svg";
import discord from "../assets/brand-discord.svg";
import instagram from "../assets/brand-instagram.svg";
import facebook from "../assets/brand-facebook.svg";
import robot from "../assets/robot.svg";

function Footer() {
  return (
    <>
      <div className="divider" />
      <div className="footer">
        <div className="footer-wrapper">
          <img className="sms-fb-logo" src={fightbotics} alt="SMS Logo" />
          <img className="sms-fb-logo" src={sms} alt="Fightbotics Logo" />
        </div>

        <ul>
          <a href="https://discord.gg/b8jzzDYWn6">
            <img src={discord} className="icon" alt="" />
            Discord
          </a>
          <a href="https://www.instagram.com/fightbotics/">
            <img src={instagram} className="icon" alt="" />
            Instagram
          </a>
          <a href="https://www.facebook.com/Fightbootics">
            <img src={facebook} className="icon" alt="" />
            Facebook
          </a>
          <a href="https://fightbotics.com/webtim">
            <img src={robot} className="icon" alt="" />S 💚 M,N i M
          </a>
        </ul>
      </div>
    </>
  );
}

export default Footer;
