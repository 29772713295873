import React from "react";
import "../styles/news.css";
import "../styles/Galerija.css";

function News({ title, date, text, imageUrl }) {
  return (
    <div className="news">
      <div className="head">
        <div className="title">{title}</div>
        <div className="date">{date}</div>
      </div>
      <div className="news-body">
        <div className="text">{text}</div>
      </div>
      {imageUrl ? (
        <div className="news-body image" onClick={() => {
          imagePopup(imageUrl);
        }}>
          <img
            alt="slika"
            src={imageUrl}
          />
        </div>
      ) : null}
      {imageUrl ? (
        <div className="popup">
          <div className="popup-cont" onClick={close}>
            <img className="popup-image" id="popup-image" />
          </div>
        </div>
      ) : null}
    </div>

  );
}

function imagePopup(object) {
  const overlay = document.querySelector(".popup");
  overlay.style.cssText = "display:flex;";
  let imageModal = document.getElementById("popup-image");
  imageModal.src = object;
}

function close() {
  const overlay = document.querySelector(".popup");
  overlay.style.cssText = "display:none;";
}

export default News;
