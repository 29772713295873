import { useState, useRef, useEffect } from "react";
import { storage } from "../firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import Carousel from "react-grid-carousel";
import left from "../assets/chevron-left.svg";
import right from "../assets/chevron-right.svg";
import "../styles/Galerija.css";

const imageListRef = ref(storage, "carousel/");

const CarouselPics = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    getImages(ref);
  }, []);

  const getImages = async () => {
    const imageUrls = [];
    const res = await listAll(imageListRef);
    await Promise.all(
      res.items.map(async (image) => {
        const url = await getDownloadURL(image);
        imageUrls.push(url);
      })
    );
    setImages(imageUrls);
  };

  return (
    <div className="carousel">
      <Carousel
        cols={3}
        rows={1}
        autoplay={4000}
        arrowLeft={
          <div className="arrow">
            <img src={left} />
          </div>
        }
        arrowRight={
          <div className="arrow" id="right">
            <img src={right} />
          </div>
        }
        hideArrow={true}
        mobileBreakpoint={500}
        responsiveLayout={[
          { breakpoint: 750, cols: 2, rows: 1, gap: 5 },
          { breakpoint: 499, autoplay: 3000, loop: true },
        ]}
        loop
      >
        {images.map((image, index) => (
          <Carousel.Item key={index} className="carousel-item">
            <img
              className="image"
              src={image}
              onClick={() => {
                imagePopup(image, index);
              }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="popup">
        <div className="popup-cont" onClick={close}>
          <img className="popup-image" id="popup-image" />
        </div>
      </div>
    </div>
  );
};

function imagePopup(object, i) {
  const overlay = document.querySelector(".popup");
  overlay.style.cssText = "display:flex;";
  let imageModal = document.getElementById("popup-image");
  imageModal.src = object;
}

function close() {
  const overlay = document.querySelector(".popup");
  overlay.style.cssText = "display:none;";
}

export default CarouselPics;
