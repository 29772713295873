import React, { useEffect, useState } from "react";
import "../styles/counter.css";

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    console.log("effect used");
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const addLeadingZero = (value) => {
    return value.toString().padStart(2, "0");
  };

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className="section">
      <h1 className="title">Sljedeći Event</h1>
      {days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? (
        <div className="time-left">
          <h1 className="title">Event je započeo! 🎉</h1>
        </div>
      ) : (
        <div className="time-left">
          <div className="number-box">
            <div className="number">{addLeadingZero(days)}</div>
            <div className="date-text">Dana</div>
          </div>
          <div className="number-box">
            <div className="number">{addLeadingZero(hours)}</div>
            <div className="date-text">Sati</div>
          </div>
          <div className="number-box">
            <div className="number">:</div>
          </div>
          <div className="number-box">
            <div className="number">{addLeadingZero(minutes)}</div>
            <div className="date-text">Minuta</div>
          </div>
          <div className="number-box">
            <div className="number">:</div>
          </div>
          <div className="number-box">
            <div className="number">{addLeadingZero(seconds)}</div>
            <div className="date-text">Sekundi</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Countdown;
