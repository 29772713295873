import {
  doc,
  collection,
  getDocs,
  setDoc,
  addDoc,
  query,
  orderBy,
  serverTimestamp,
  deleteDoc,
  updateDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { firestore, storage } from "../firebase";
import News from "../components/News";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import "../styles/news-section.css";
import "../styles/dashboard.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { nanoid } from 'nanoid';

import edit from "../assets/edit.svg";
import deleteIcon from "../assets/trash.svg";
import show from "../assets/eye.svg";
import hide from "../assets/eye-off.svg";

// import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [checked, setChecked] = useState(false);
  const [changed, hasChanged] = useState(false);
  const [removed, hasRemoved] = useState(false);
  const [editMode, editing] = useState(false);
  const [editItemId, current] = useState("");
  const [news, setNews] = useState("");
  const [newNews, setNewnews] = useState("");
  const [event, setEvent] = useState("");
  const [eventState, setEventState] = useState(false);
  const [onGoingApplications, setOnGoingApplications] = useState(false);
  const [onGoingApplicationsLink, setonGoingApplicationsLink] = useState("");
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const refCol = collection(firestore, "posts");

  let currUrl = null;
  // const navigate = useNavigate();
  // const handleLost = () => navigate("/NotFound", { replace: true });

  //Get data array from Firebase with id
  async function getData() {
    const q = query(refCol, orderBy("dateCreated", "desc"));
    const data = await getDocs(q);
    let arr = [];
    data.forEach((doc) => {
      // Extract the data of each document and store it in an object
      const documentData = doc.data();

      // Optionally, include the document ID in the object
      const documentObject = {
        id: doc.id,
        ...documentData,
      };

      // Add the document object to the dataArray
      arr.push(documentObject);
    });
    setPosts(arr);
  }

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  }

  async function getNews() {
    const refCol = doc(firestore, "news", "current");
    const snap = await getDoc(refCol);

    if (snap.exists()) {
      setNews(snap.data().body);
    } else {
      console.error("NO NEWS!");
    }
  }

  async function onGointApplications() {
    const refCol = doc(firestore, "apply", "apply");
    const snap = await getDoc(refCol);

    if (snap.exists()) {
      setOnGoingApplications(snap.data().visible);
      setonGoingApplicationsLink(snap.data().link);
    } else {
      console.error("APPLICATIONS ARE CLOSED!");
    }
    document.getElementById("link-visible").checked = snap.data().visible;
  }

  async function getDate() {
    const refCol = collection(firestore, "event");
    const snap = await getDoc(doc(refCol, "nextEvent"));

    const offset = new Date().getTimezoneOffset() * 60;

    setEvent(snap.data().dateTime);
    document.getElementById("datepicker").value = new Date(
      (snap.data().dateTime.seconds - offset) * 1000
    )
      .toISOString()
      .slice(0, -1);

    setEventState(snap.data().state);
    document.getElementById("event-visible").checked = snap.data().state;
  }

  async function handleNewsChange(e) {
    e.preventDefault();
    const refCol = collection(firestore, "news");

    try {
      await setDoc(doc(refCol, "current"), {
        body: newNews,
      });
    } catch (error) {
      console.error("ERROR!");
    }
  }

  async function setApplications(e) {
    e.preventDefault();
    const refCol = collection(firestore, "apply");

    try {
      await setDoc(doc(refCol, "apply"), {
        link: onGoingApplicationsLink.toString(),
        visible: onGoingApplications,
      });
    } catch (error) {
      console.error("ERROR!");
    }
  }

  async function handleEventChange(e) {
    e.preventDefault();
    const refCol = collection(firestore, "event");

    try {
      await setDoc(doc(refCol, "nextEvent"), {
        dateTime: Timestamp.fromDate(new Date(event)),
        state: eventState,
      });
    } catch (error) {
      console.error("ERROR!");
    }
  }

  //submit handler, checks if it's a new post or edit
  async function handleSubmit(e) {
    e.preventDefault();

    if (image != null) {
      let idimg = nanoid();
      let imageRef = ref(storage, "postSlike/" + idimg);

      await uploadBytes(imageRef, image);
      currUrl = await getDownloadURL(imageRef);
    }

    const data = {
      title: title,
      text: body,
      visible: checked,
      image: currUrl,
      dateCreated: serverTimestamp(),
    };

    if (editMode) {
      // console.log(editItemId);
      try {
        await setDoc(doc(refCol, editItemId), data).then(() => {
          hasChanged(!changed);
        });
      } catch (err) {
        console.error("writeToDB failed. reason :", err);
      }
      editing(false);
    } else {
      try {
        await addDoc(refCol, data).then(() => {
          hasChanged(!changed);
        });
      } catch (err) {
        console.error("writeToDB failed. reason :", err);
      }
    }

    resetFields();
  }

  //Deletes an item from post list
  async function deleteItem(i) {
    try {
      await deleteDoc(doc(refCol, i)).then(() => {
        alert("Uspješno izbrisano");
        hasRemoved(!removed);
      });
    } catch (err) {
      console.error("writeToDB failed. reason :", err);
    }
  }

  //Post editing function
  function editItem(title, body, visible, i) {
    editing(true);
    document.getElementById("form").scrollIntoView({ behavior: "smooth" });
    setTitle(title);
    document.getElementById("title").value = title;

    setBody(body);
    document.getElementById("body").value = body;

    setChecked(visible);
    document.getElementById("post-visibility").checked = visible;
    current(i);
  }

  //Change visibility of a post
  async function changeVisibility(i, isVisible) {
    try {
      await updateDoc(doc(refCol, i), {
        visible: !isVisible,
      });
      hasChanged(!changed);
    } catch (err) {
      console.error("writeToDB failed. reason :", err);
    }
  }

  //Clear input fields
  function resetFields() {
    editing(false);

    setTitle("");
    document.getElementById("title").value = "";

    setBody("");
    document.getElementById("body").value = "";

    setChecked(false);
    document.getElementById("post-visibility").checked = false;
    current("");

    currUrl = null;
    setImage(null);
  }

  useEffect(() => {
    getNews();
    getDate();
    onGointApplications();
  }, []);

  useEffect(() => {
    getData();
  }, [changed, removed, editMode]);

  return (
    <div>
      <div className="section">
        <div className="left-side center">
          <div>
            <div className="title">Sljedeći event</div>
            <form onSubmit={handleEventChange}>
              <div className="input-field">
                <label>Datum</label>
                <input
                  type="datetime-local"
                  timezone="CET"
                  id="datepicker"
                  onChange={(e) => setEvent(e.target.value)}
                ></input>
                <div className="input-field">
                  <div>
                    Vidljivo
                    <input
                      type="checkbox"
                      id="event-visible"
                      onChange={(e) => setEventState(!eventState)}
                    ></input>
                  </div>
                </div>

                <div className="buttons">
                  <button type="submit" className="button">
                    Objavi!
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="right-side center">
          <div>
            <div className="title">Promijeni novost</div>
            <form onSubmit={handleNewsChange}>
              <div className="input-field">
                <label>Unesi novi tekst</label>
                <input
                  type="text"
                  placeholder={news}
                  required
                  onChange={(e) => setNewnews(e.target.value)}
                ></input>
                <div className="buttons">
                  <button type="submit" className="button">
                    Objavi!
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="left-side center">
          <div>
            <div className="title">Postavi prijave</div>
            <form onSubmit={setApplications}>
              <div className="input-field">
                <label>Unesi novi link za prijave</label>
                <input
                  type="text"
                  placeholder={onGoingApplicationsLink}
                  required
                  onChange={(e) => setonGoingApplicationsLink(e.target.value)}
                ></input>
                <div className="input-field">
                  <div>
                    Vidljivo
                    <input
                      type="checkbox"
                      id="link-visible"
                      onChange={(e) =>
                        setOnGoingApplications(!onGoingApplications)
                      }
                    ></input>
                  </div>
                </div>

                <div className="buttons">
                  <button type="submit" className="button">
                    Objavi!
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="news-section column">
        <div className="section column full-width" id="form">
          <div
            className="title"
            style={{ alignSelf: "center", paddingBottom: 1 + "rem" }}
          >
            {editMode ? "Uređuješ obavijest!" : "Dodaj novu obavijest"}
          </div>
          <form onSubmit={handleSubmit} className="new-form">
            <div className="input-field">
              <label>Naslov</label>
              <input
                type="text"
                placeholder="Naslov obavijesti"
                id="title"
                required
                onChange={(e) => setTitle(e.target.value)}
              ></input>
            </div>
            <div className="input-field">
              <label>Sadržaj</label>
              <textarea
                type="text"
                id="body"
                required
                placeholder="Tekst obavijesti"
                onChange={(e) => setBody(e.target.value)}
                className="big"
              />
            </div>

            <div className="input-field">
              <div>
                Obavijest je vidljiva:{" "}
                <input
                  type="checkbox"
                  id="post-visibility"
                  onChange={(e) => setChecked(!checked)}
                ></input>
              </div>
            </div>

            <div className="input-field">
              <div>
                Upload image: <br />
                <input
                  type="file"
                  onChange={handleImageChange}
                ></input>
              </div>
            </div>

            <div className="buttons">
              <button type="submit" className="button">
                Submit
              </button>
              {editMode && (
                <button type="reset" className="button">
                  Reset
                </button>
              )}
            </div>
          </form>
        </div>

        {posts.map((object, i) => (
          <>
            <News
              title={object.title}
              date={String(
                object.dateCreated.toDate().toLocaleDateString("hr-HR")
              )}
              imageUrl={object.image}
              text={object.text}
              key={i}
            />
            <div className="actions">
              {/* <div>{object.visible ? "Vidljivo" : "Nevidljivo"}</div> */}
              <Button
                text={"Uredi"}
                className="compact"
                icon={edit}
                onClick={() =>
                  editItem(object.title, object.text, object.visible, object.id)
                }
              />
              <Button
                text={object.visible ? "Sakrij" : "Prikaži"}
                icon={object.visible ? hide : show}
                className="compact"
                onClick={() => changeVisibility(object.id, object.visible)}
              />
              <Button
                text={"Izbriši"}
                icon={deleteIcon}
                className="compact"
                onClick={() => deleteItem(object.id)}
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
