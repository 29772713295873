function NotFound() {
  return (
    <div className="error-404">
      <div className="section">
        <div className="error"> ERROR 404 </div>
        <div className="subtitle" style={{ alignSelf: "center" }}>
          Nažalost, ta stranica ne postoji.
        </div>
      </div>
    </div>
  );
}

export default NotFound;
