import React from "react";
import Button from "../components/Button";
import "../styles/section.css";
import ext_link from "..//assets/external-link.svg";
import { useNavigate } from "react-router-dom";

function Section({
  title,
  icon,
  subtitle,
  text,
  button,
  image,
  location = "",
  rightWide,
  imgAlpha,
  children,
}) {
  const navigate = useNavigate();
  const handleOnClick = () => navigate("/" + location, { replace: false });
  return (
    <section className="section">
      <div className="left-side" style={{ flexBasis: 100 - rightWide + "vw" }}>
        <a
          className="title-box"
          onClick={location ? handleOnClick : null}
          id={location && "link"}
        >
          <h1 className="title">{title}</h1>
          {icon ? <img src={ext_link} className="icon" /> : null}
        </a>
        <h2 className="subtitle">{subtitle}</h2>
        <div className="text">{text}</div>
        {button ? <Button text={button} /> : null}
      </div>
      <div className="right-side" style={{ flexBasis: rightWide + "vw" }}>
        {image ? (
          <img
            className={imgAlpha ? "image image-alpha" : "image"}
            src={image}
          />
        ) : null}
        {children}
      </div>
    </section>
  );
}

export default Section;
