import React from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

import "../styles/admin.css";

import Button from "../components/Button";

const Signout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <>
      <div className="section column" style={{ minHeight: 60 + "vh" }}>
        <div
          className="title"
          style={{ alignSelf: "center", paddingBottom: 1 + "rem" }}
        >
          Želiš se odlogirat?
        </div>
        <Button onClick={handleLogout} text={"Logout"} />
      </div>
    </>
  );
};

export default Signout;
