import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

import Button from "../components/Button";
import download from "../assets/download.svg";
import link from "../assets/external-link.svg";
import discord from "../assets/brand-discord.svg";

import { firestore } from "../firebase.js";
import { doc, getDoc } from "firebase/firestore";

import { useEffect, useState } from "react";

function Compete() {
  const [applications, setApplications] = useState(false);
  const [applicationsLink, setApplicationsLink] = useState("");
  const getRobotCreationManual = async () => {
    const fileRef = ref(storage, "dokumenti/pravilnik_za_izradu.pdf");
    getDownloadURL(fileRef)
      .then((url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "pravilnik_za_izradu.pdf";
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getCompetitionManual = async () => {
    const fileRef = ref(storage, "dokumenti/pravilnik_za_natjecanje.pdf");
    getDownloadURL(fileRef)
      .then((url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "pravilnik_za_natjecanje.pdf";
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getHobbyRules = async () => {
    const fileRef = ref(storage, "dokumenti/vodic_hobisti.pdf");
    getDownloadURL(fileRef)
      .then((url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "vodic_hobisti.pdf";
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getStudentRules = async () => {
    const fileRef = ref(storage, "dokumenti/vodic_stud.pdf");
    getDownloadURL(fileRef)
      .then((url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "vodic_stud.pdf";
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  async function onGointApplications() {
    const ref = doc(firestore, "apply", "apply");
    const snap = await getDoc(ref);

    if (snap.exists()) {
      setApplications(snap.data().visible);
      setApplicationsLink(snap.data().link);
    } else {
      console.error("APPLICATIONS ARE CLOSED!");
    }
  }

  useEffect(() => {
    onGointApplications();
  }, []);

  return (
    <div>
      <div className="hero small-hero">
        <h1 className="event-name smaller">Natječi se!</h1>
        <h2 className="subtitle">Izradi i ti svog robota</h2>
        {applications ? (
          <Button
            onClick={() => {
              window.location.href = applicationsLink.toString();
            }}
            text={"Prijavi se!"}
            className="hero-button"
          />
        ) : (
          <></>
        )}
      </div>
      <div className="divider" />
      <div className="section dark">
        <h1 className="title padding-bottom padding-right">
          Pravilnici i <br /> vodiči
        </h1>
        <div className="cards">
          {/* Pravilnik */}
          <div className="card">
            <div className="card-head">
              <div className="title">Vodič za prijavu hobističkih timova</div>
            </div>
            <div className="card-body">
              <div className="text">
                Hobist si i želiš se natjecati? Preuzmi dokument u kojem je
                objašnjena prijava na Fightbotics natjecanje.
              </div>
              <Button
                onClick={getHobbyRules}
                text={"Preuzmi vodič"}
                icon={download}
              />
            </div>
          </div>

          {/* Pravilnik */}
          <div className="card">
            <div className="card-head">
              <div className="title">Vodič za prijavu studentskih timova</div>
            </div>
            <div className="card-body">
              <div className="text">
                Student si i želiš se natjecati? Sufinanciramo komponente
                odabranim studentskim timovima!
              </div>
              <Button
                onClick={getStudentRules}
                text={"Preuzmi vodič"}
                icon={download}
              />
            </div>
          </div>

          {/* Pravilnik */}
          <div className="card">
            <div className="card-head">
              <div className="title">Pravilnik za izradu robota</div>
            </div>
            <div className="card-body">
              <div className="text">
                Ovdje se nalazi dokument koji opisuje kakav robot smiješ
                napraviti. Pažljivo čitaj!
              </div>
              <Button
                onClick={getRobotCreationManual}
                text={"Preuzmi pravilnik"}
                icon={download}
              />
            </div>
          </div>

          {/* Pravilnik */}
          <div className="card">
            <div className="card-head">
              <div className="title">Pravilnik za natjecanje</div>
            </div>
            <div className="card-body">
              <div className="text">
                Ovdje se nalazi dokument u kojem ćeš saznati sve o pravilima
                tijekom natjecanja, pravilima borbi i njihovom bodovanja
              </div>
              <Button
                onClick={getCompetitionManual}
                text={"Preuzmi pravilnik"}
                icon={download}
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="divider" />
      <div className="section dark">
        <h1 className="title padding-bottom padding-right">
          Korisne <br /> poveznice
        </h1>
        <div className="cards">
          {/* Pravilnik */}
          <div className="card">
            <div className="card-head">
              <div className="title">Fightbotics Discord</div>
            </div>
            <div className="card-body">
              <div className="text">
                Pridruži se našem discordu za komunikaciju sa drugim
                natjecateljima i savjete za izradu robota!
              </div>
              <Button
                text={"Discord"}
                icon={discord}
                link={"https://discord.gg/b8jzzDYWn6"}
              />
            </div>
          </div>

          {/* Pravilnik */}
          <div className="card">
            <div className="card-head">
              <div className="title">NHRL Discord</div>
            </div>
            <div className="card-body">
              <div className="text">
                Razgovaraj s ljubiteljima robota u NHRL-ovom Discordu.
              </div>
              <Button
                text={"Discord"}
                icon={discord}
                link={"https://discord.gg/nhrl"}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-head">
              <div className="title">Riobotz Combot Tutorial</div>
            </div>
            <div className="card-body">
              <div className="text">
                Pročitaj opsežnu knjigu koja sadrži sve upute za izradu moćnih
                robota.
              </div>
              <Button
                text={"Preuzmi knjigu"}
                icon={link}
                link={"https://www.riobotz.com/tutorials"}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-head">
              <div className="title">Repeat Robotics Handbook</div>
            </div>
            <div className="card-body">
              <div className="text">
                Pročitaj i saznaj više o čestim konfiguracijama robota i
                načinima izrade.
              </div>
              <Button
                text={"Preuzmi knjigu"}
                icon={link}
                link={"https://repeat-robotics.com/handbook/"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compete;
