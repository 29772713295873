import Section from "../components/Section";
import Counter from "../components/Counter";
import News from "../components/News";
import CarouselPics from "../components/Carousel";

import "../styles/Main.css";

import fbexmp from "../assets/fb_exmp.webp";
import robot from "../assets/robot_exmp.png";

import { firestore } from "../firebase.js";
import {
  collection,
  getDocs,
  limit,
  query,
  where,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";

import { useEffect, useState } from "react";
import Button from "../components/Button";

function Main() {
  const [post, setPosts] = useState([]);
  const [news, setNews] = useState("");
  const [event, setEvent] = useState("");
  const [eventState, setEventState] = useState(false);
  const [applications, setApplications] = useState(false);
  const [applicationsLink, setApplicationsLink] = useState("");
  const ref = collection(firestore, "posts");


  async function getData() {
    const q = query(
      ref,
      where("visible", "==", true),
      orderBy("dateCreated", "desc"),
      limit(1)
    );
    const data = await getDocs(q);
    let arr = {};

    data.forEach((doc) => {
      arr = doc.data();
    });
    setPosts(arr);
  }

  async function getNews() {
    const ref = doc(firestore, "news", "current");
    const snap = await getDoc(ref);

    if (snap.exists()) {
      setNews(snap.data().body);
    } else {
      console.error("NO NEWS!");
    }
  }

  async function getEventDate() {
    const ref = doc(firestore, "event", "nextEvent");
    const snap = await getDoc(ref);

    if (snap.exists()) {
      setEvent(snap.data().dateTime.seconds * 1000);
      setEventState(snap.data().state);
    } else {
      console.error("NO EVENT!");
    }
  }

  async function onGointApplications() {
    const ref = doc(firestore, "apply", "apply");
    const snap = await getDoc(ref);

    if (snap.exists()) {
      setApplications(snap.data().visible);
      setApplicationsLink(snap.data().link);
    } else {
      console.error("APPLICATIONS ARE CLOSED!");
    }
  }

  useEffect(() => {
    getData();
    getNews();
    getEventDate();
    onGointApplications();
  }, []);

  return (
    <div className="Main">
      {/* <body> */}

      {/* HERO */}
      <div className="hero">
        <h1 className="event-name">FIGHTBOTICS</h1>
        <h2 className="subtitle">Hrvatske borbe robota</h2>
        {applications ? (
          <Button
            onClick={() => {
              window.location.href = applicationsLink.toString();
            }}
            text={"Prijavi se!"}
            className="hero-button"
          />
        ) : (
          <></>
        )}
      </div>

      {/* COUNTER do iduceg eventa, zasad ne treba, poslije cemo pokazat */}
      {eventState ? <Counter targetDate={event} /> : <></>}

      <Section
        title={"Bori se i TI"}
        // subtitle={"Izradi i uništi!"}
        text={
          "Želiš izraditi svoju mašinu za uništavanje i boriti se na našem natjecanju? Okupi svoj tim, proučite pravilnike, izradite CAD i prijavi se na naše natjecanje!"
        }
        icon={true}
        location={"natjecanje"}
        image={robot}
        rightWide={30}
        imgAlpha={true}
      />

      <div className="marquee">
        <ul className="marquee-content">
          <span>{news}</span>
          <span>{news}</span>
          <span>{news}</span>
        </ul>
        <ul className="marquee-content" aria-hidden="true">
          <span>{news}</span>
          <span>{news}</span>
          <span>{news}</span>
        </ul>
      </div>
      {/* Obavijesti poseban function */}
      <Section
        title={"Obavijesti"}
        icon={true}
        location={"obavijesti"}
        subtitle={"Najnovije iz Fightboticsa"}
      >
        <News
          title={post?.title}
          date={String(post.dateCreated?.toDate().toLocaleDateString("hr-HR"))}
          text={post?.text}
          imageUrl={post?.image}
        />
      </Section>

      <Section
        title={"Što je Fightbotics?"}
        text={
          "Fightbotics je natjecanje u organizaciji studenata FSB-a i FER-a  koje entuzijastima omogućuje da upogone svoje robote i međusobno se bore na jedinstvenom događaju u našoj custom-made areni za borbu. Na natjecanje se mogu prijaviti srednjoškolci, studenti, hobisti i tvrtke te je sudjelovanje otvoreno i besplatno za sve. U sklopu natjecanja organiziramo i praktične radionice koje odabranim studentskim timovima daju priliku da osmišljene koncepte borbenih robota prenesu u stvarnost!"
        }
        image={fbexmp}
      />

      {/* isto poseban jsx za galeriju */}
      <Section
        title={"Slike"}
        subtitle={"Pogledaj fotke s prošlih natjecanja"}
        icon={true}
        rightWide={80}
        location={"galerija"}
      >
        <CarouselPics></CarouselPics>
      </Section>

      <Section
        title={"Sponzori"}
        icon={true}
        location={"sponzori"}
        subtitle={"Ovaj projekt ne bi bio izvediv bez njihove potpore"}
        text={
          "Zajedničkom suradnjom Fightbotics partneri otvaraju novi aspekt promocije svojih proizvoda kao i uvid u kaotičan svijet strastvenih natjecatelja borbenih robota."
        }
        // Fightbotics stoji na ramenima tehnoloških divova! Bez njihove realizacijske i sponzorske podrške  događanja uopće ne bi niti bilo. 
        // button={"Postani sponzor!"}
      />
      {/* </body> */}
    </div>
  );
}

export default Main;
