import React from "react";
import { useEffect, useState, useRef } from "react";
import { storage } from "../firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import "../styles/Galerija.css";
import "../styles/sponzori.css";

const imageListRef = ref(storage, "slike/");

function Galerija() {
  const [pictures, setPictures] = useState([]);

  async function getImages(ref) {
    const imageUrls = [];
    const res = await listAll(ref);
    await Promise.all(
      res.items.map(async (image) => {
        const url = await getDownloadURL(image);
        imageUrls.push(url);
      })
    );
    return imageUrls;
  }

  async function getFolders() {
    const response = await listAll(imageListRef);
    const folders = sortByFolderName(response);

    for (let i of folders.prefixes) {
      const result = await getImages(i);
      setPictures((prev) => [
        ...prev,
        {
          name: i.name.slice(0, -1),
          images: result,
        },
      ]);
    }
  }

  function sortByFolderName(folders) {
    folders.prefixes.sort((a, b) => {
      const pathnameA = a.fullPath.slice(-1).toLowerCase();
      const pathnameB = b.fullPath.slice(-1).toLowerCase();
      return pathnameB.localeCompare(pathnameA);
    });

    return folders;
  }

  useEffect(() => {
    getFolders();
  }, []);

  return (
    <>
      <div className="section column">
        <div className="wrapper">
          {pictures.map((obj, i) => (
            <div>
              <div key={i}>
                <h1 className="title">{obj.name}</h1>
                <div className="divider" />
                <div className="gallery-grid">
                  {obj.images.map((img, index) => (
                    <div
                      key={index}
                      className="item"
                      style={{ "--item": i + 1 }}
                      onClick={() => {
                        imagePopup(img, i);
                      }}
                    >
                      <img
                        className="image"
                        src={img}
                        alt="image"
                        loading="lazy"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="popup">
        <div className="popup-cont" onClick={close}>
          <img className="popup-image" id="popup-image" />
        </div>
      </div>
    </>
  );
}

function imagePopup(object, i) {
  const overlay = document.querySelector(".popup");
  overlay.style.cssText = "display:flex;";
  let imageModal = document.getElementById("popup-image");
  imageModal.src = object;
}

function close() {
  const overlay = document.querySelector(".popup");
  overlay.style.cssText = "display:none;";
}

export default Galerija;
