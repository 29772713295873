import React from "react";
import News from "../components/News";
import "../styles/news-section.css";
import { firestore } from "../firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { useEffect, useState } from "react";

function Obavijesti() {
  const [posts, setPosts] = useState([]);
  const ref = collection(firestore, "posts");

  async function getData() {
    const q = query(
      ref,
      where("visible", "==", true),
      orderBy("dateCreated", "desc")
    );

    const data = await getDocs(q);
    let arr = [];
    data.forEach((doc) => {
      // Extract the data of each document and store it in an object
      const documentData = doc.data();

      // Add the document object to the dataArray
      arr.push(documentData);
    });
    setPosts(arr);
    console.log(arr);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="news-section">
        <div className="title">Obavijesti</div>
        {posts.map((object, i) => (
          <News
            title={object.title}
            date={String(
              object.dateCreated.toDate().toLocaleDateString("hr-HR")
            )}
            text={object.text}
            imageUrl={object.image}
            key={i}
          />
        ))}
      </div>
    </div>
  );
}

export default Obavijesti;
