import React from "react";
import "../styles/button.css";

function Button({ text, link, className, icon, onClick }) {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      {icon && <img src={icon} />}
      <a href={link}>{text}</a>
    </button>
  );
}

export default Button;
