import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyDv26Hfrl61rZS6Zyw4ZyjUK88UYCL_D7A",

  authDomain: "fightbotics-31a78.firebaseapp.com",

  projectId: "fightbotics-31a78",

  storageBucket: "fightbotics-31a78.appspot.com",

  messagingSenderId: "370077263947",

  appId: "1:370077263947:web:3787d0b4c4546ec7dec5ce",
};

const app = firebase.initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const remoteConfig = getRemoteConfig(app);
