import React, { useState, useEffect, useRef } from "react";
import "../styles/navbar.css";
import menu from "../assets/menu_icon.svg";
import { useMediaQuery } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

function Navbar() {
  const [user] = useAuthState(auth);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isLightMode, setLightMode] = useState(false);

  return (
    <nav className="nav-cont">
      <div className="navbar">
        <div className="nav-item">
          <Link to={"/"} className="event-name">
            FIGHTBOTICS
          </Link>
        </div>
        <div className="nav-item" id="links">
          <Link to={"/obavijesti"}>Obavijesti</Link>
          <Link to={"/galerija"}>Galerija</Link>
          <Link to={"/natjecanje"}>Natjecanje</Link>
          <Link to={"/sponzori"}>Sponzori</Link>
          <Link to={"/kontakt"}>Kontakt</Link>
          {user ? <Link to={"/dashboard"}>Dashboard</Link> : null}
          {user ? <Link to={"/signout"}>Signout</Link> : null}
          <li
            className="menu-icon"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <img src={menu} />
          </li>
        </div>
      </div>
      <div className={isNavExpanded ? "dropdown-extended" : "dropdown"}>
        <Link
          onClick={() => setIsNavExpanded(!isNavExpanded)}
          to={"/obavijesti"}
        >
          Obavijesti
        </Link>
        <Link onClick={() => setIsNavExpanded(!isNavExpanded)} to={"/galerija"}>
          Galerija
        </Link>
        <Link
          onClick={() => setIsNavExpanded(!isNavExpanded)}
          to={"/natjecanje"}
        >
          Natjecanje
        </Link>
        <Link onClick={() => setIsNavExpanded(!isNavExpanded)} to={"/sponzori"}>
          Sponzori
        </Link>
        <Link onClick={() => setIsNavExpanded(!isNavExpanded)} to={"/kontakt"}>
          Kontakt
        </Link>
        {user ? (
          <Link
            onClick={() => setIsNavExpanded(!isNavExpanded)}
            to={"/dashboard"}
          >
            Dashboard
          </Link>
        ) : null}
        {user ? (
          <Link
            onClick={() => setIsNavExpanded(!isNavExpanded)}
            to={"/signout"}
          >
            Signout
          </Link>
        ) : null}
      </div>
    </nav>
  );
}

export default Navbar;
