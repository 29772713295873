import "../styles/kontakt.css";
import discord from "../assets/brand-discord.svg";
import instagram from "../assets/brand-instagram.svg";
import facebook from "../assets/brand-facebook.svg";

function Kontakt() {
  return (
    <div className="section contact fill">
      <div className="left-side" />
      <div className="right-side">
        <h1 className="title">Kontaktiraj nas!</h1>
        <h2 className="subtitle">Uvijek smo dostupni.</h2>
        <div className="text">
          Pošalji nam mail na{" "}
          <a href="mailto:fightbotics@gmail.com" id="mail">
            fightbotics@gmail.com
          </a>{" "}
          ili nam se javi na društevnim mrežama!
        </div>
        <div className="socials">
          <ul>
            <a id="soc-links" href="https://discord.gg/b8jzzDYWn6">
              <img src={discord} className="icons" alt="" />
              Discord
            </a>
            <a id="soc-links" href="https://www.instagram.com/fightbotics/">
              <img src={instagram} className="icons" alt="" />
              Instagram
            </a>
            <a id="soc-links" href="https://www.facebook.com/Fightbootics">
              <img src={facebook} className="icons" alt="" />
              Facebook
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Kontakt;
